import { AnalyticsBrowser } from '@segment/analytics-next';
import { createContext, useContext, useMemo } from 'react';

import { type EnumsPlatform } from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../hooks/useLiveCallback';

type GlobalStoreKey = 'platform';

type GlobalStore = {
  platform?: EnumsPlatform;
};

type State = {
  analytics: AnalyticsBrowser;
  global: Map<GlobalStoreKey, unknown>;
};

const AnalyticsContext = createContext<State | undefined>(undefined);

export function AnalyticsProvider(props: {
  writeKey: string;
  test?: boolean;
  children?: React.ReactNode;
}): JSX.Element {
  const ctx = useMemo(
    () => ({
      analytics: props.test
        ? new AnalyticsBrowser()
        : AnalyticsBrowser.load({ writeKey: props.writeKey }),
      global: new Map<GlobalStoreKey, unknown>(),
    }),
    [props.writeKey, props.test]
  );
  return (
    <AnalyticsContext.Provider value={ctx}>
      {props.children}
    </AnalyticsContext.Provider>
  );
}

function useAnalyticsContext() {
  const ctx = useContext(AnalyticsContext);
  if (!ctx) {
    throw new Error('AnalyticsProvider is not in the tree!');
  }
  return ctx;
}

export function useAnalytics(): AnalyticsBrowser {
  const { analytics } = useAnalyticsContext();
  return analytics;
}

export function useWriteAnalyticsGlobalStore<K extends keyof GlobalStore>() {
  const { global } = useAnalyticsContext();
  return useLiveCallback((key: K, val: GlobalStore[K]) => {
    global.set(key, val);
  });
}

export function useReadAnalyticsGlobalStore<K extends keyof GlobalStore>() {
  const { global } = useAnalyticsContext();
  return useLiveCallback((key: K): GlobalStore[K] | undefined => {
    const val = global.get(key);
    if (val === undefined) return undefined;
    return val as GlobalStore[K];
  });
}
